import { z } from 'zod';
import { dateTimeSchema, uidSchema } from './shared';

export const couponSchema = z
  .object({
    account_id: z.string().nullable().default(null),
    active: z.boolean().default(true),
    amount_off: z.number().gt(0).nullable().default(null),
    buyer_id: uidSchema.nullable().default(null),
    code: z
      .string()
      .refine((x) => x.trim().length > 2, 'Code must be at least 3 characters')
      .refine((x) => x.trim().length < 21, 'Code must be at most 20 characters')
      .transform((x) => x.trim().toUpperCase()),
    created: dateTimeSchema,
    expires_at: dateTimeSchema.nullable().default(null),
    first_purchase_only: z.boolean().default(false),
    max_redemptions: z.number().int().gte(0).nullable().default(null),
    max_per_customer: z.number().int().gt(0).nullable().default(null),
    id: z.string(),
    min_amount: z.number().gt(0).nullable().default(null),
    percent_off: z.number().gt(0).nullable().default(null),
    redemptions: z.number().int().gte(0).default(0),
    seller_id: uidSchema.nullable().default(null),
    product_id: z.string().nullable().default(null),
  })
  .refine((input) => input.amount_off !== null || input.percent_off !== null, {
    message: 'Coupon must have either an amount off or percent off',
  });

export const redemptionSchema = z.object({
  coupon_id: z.string(),
  date_redeemed: dateTimeSchema,
  id: z.string(),
  order_id: z.string(),
  uid: uidSchema,
});

export interface Redemption extends z.output<typeof redemptionSchema> {}
export interface CouponInput extends z.input<typeof couponSchema> {}
export interface Coupon extends z.output<typeof couponSchema> {}
