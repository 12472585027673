import { z } from 'zod';

const blogTagSchemas = [
  z.literal('Buying'),
  z.literal('Community'),
  z.literal("How To's"),
  z.literal('News & Press'),
  z.literal('Seller Spotlight'),
  z.literal('Selling'),
  z.literal('Videos'),
] as const;

export const ALL_BLOG_TAGS = blogTagSchemas.map((schema) => schema.value);

export const blogTagSchema = z.union(blogTagSchemas);

export const blogCommentDocumentSchema = z.object({
  author_id: z.string(),
  author_name: z.string(),
  blog_id: z.string(),
  comment: z.string(),
  comment_id: z.string(),
  parent_id: z.string().nullable(),
  pfp_url: z.string(),
  timestamp: z.number().default(Date.now()),
  reply_ids: z.array(z.string()),
});

export const metaTagSchema = z.object({
  attribute1: z.tuple([z.string(), z.string()]),
  attribute2: z.tuple([z.string(), z.string()]),
});

export const blogDocumentSchema = z.object({
  id: z.string(),
  uid: z.string(),
  slug: z.string(),
  title: z.string(),
  author: z.string(),
  author_photo: z.string().optional(),
  thumbnail: z.string(),
  full_image: z.string(),
  html: z.string().default(''),
  tags: z.array(blogTagSchema),
  created: z.number().default(Date.now()),
  is_featured: z.boolean().default(false),
  description: z.string(),
  meta_tags: z.array(metaTagSchema).optional(),
  title_tag: z.string().optional(),
  hide_thumbnail: z.boolean().optional(),
});

export type MetaTag = z.infer<typeof metaTagSchema>;
export type BlogTag = z.infer<typeof blogTagSchema>;
export type BlogCommentDocument = z.infer<typeof blogCommentDocumentSchema>;
export type BlogDocument = z.infer<typeof blogDocumentSchema>;
